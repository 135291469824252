
import Vue from "vue";
import SignUpForm from "@/components/SignUp/SignUpForm.vue";
import SignUpCompleteProfile from "@/components/SignUp/SignUpCompleteProfile.vue";
import SignUpSecurityCode from "@/components/SignUp/SignUpSecurityCode.vue";
import SignUpEmailNotRegistered from "@/components/SignUp/SignUpEmailNotRegistered.vue";
import LandingVideo from "@/components/LandingPage/LandingVideo.vue";

export default Vue.extend({
  name: "SignUpMentoree",
  components: {
    SignUpForm,
    SignUpSecurityCode,
    SignUpCompleteProfile,
    SignUpEmailNotRegistered,
    LandingVideo
  },
  props: {
    userType: String
  },
  data() {
    return {
      data: {},
      activeComponent: {
        form: "SignUpForm",
        "security-code": SignUpSecurityCode,
        "email-not-registered": "SignUpEmailNotRegistered"
      },
      isPlay: false,
      videoId: "tnpXX1Xp1vM"
    };
  },
  computed: {
    player() {
      //@ts-ignore
      return this.$refs.youtube.player;
    }
  },
  methods: {
    signUp(data: any) {
      //@ts-ignore
      this.data = data;
    },
    playVideo() {
      //@ts-ignore
      this.isPlay = true;
      //@ts-ignore
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    }
  }
});
